import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { renderToStaticMarkup } from 'react-dom/server';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.css";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

// import soru ile compenant soru sirasinin aynı olması onemli yoksa slayt bozuluyor
import Carousel from "../components/Carousel";

import { EffectFade, Navigation, Pagination, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const Colchicum = () => {
  const [posts, setPosts] = useState([]);
  //const [postsh, setPostsh] = useState([]);
  const [sonuc05goster, setSonuc05] = useState([]);
  const [sonuc06goster, setSonuc06] = useState([]);
  const [sonuc07goster, setSonuc07] = useState([]);
  const [sonuc08goster, setSonuc08] = useState([]);
    
  const [sonuc011goster, setSonuc011] = useState([]);

  const [sonuc16goster, setSonuc16] = useState([]);
  const [sonuc17goster, setSonuc17] = useState([]);

  const [sonuc19goster, setSonuc19] = useState([]);
  const [sonuc20goster, setSonuc20] = useState([]);
  const [sonuc21goster, setSonuc21] = useState([]);

  const [sonuc23goster, setSonuc23] = useState([]);
  const [sonuc24goster, setSonuc24] = useState([]);
  const [sonuc25goster, setSonuc25] = useState([]);

  const [sonuc27goster, setSonuc27] = useState([]);

  const [sonuc30goster, setSonuc30] = useState([]);
  const [sonuc31goster, setSonuc31] = useState([]);
  const [sonuc32goster, setSonuc32] = useState([]);
  const [sonuc33goster, setSonuc33] = useState([]);


  const location = useLocation();
  const postId = location.pathname.split("/")[2];
  const records = posts.slice();
  //const recordsh = postsh.slice();
  const sonuc19 = 35;
  const sonuc24 = 24;
  const sonuc11 = 11;

  useEffect(() => {
  
  // sunum bilgileri
    const fetchData = async () => {
      //console.log("use effect home calisti");
      try {
        //console.log("postId", postId);
        const res = await axios.get(
          `https://api.recordatifocus.com/sunum-bilgileri/${postId}`
        );
        //console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
// sunum bilbileri
// 
// Soru 01
    const soruBir = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-01/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru01 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc05goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_05.jpg"/></div>;
        const sonuc06goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_06.jpg"/></div>;
        const sonuc07goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_07.jpg"/></div>;
        const sonuc08goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_08.jpg"/></div>;
      	setSonuc05(sonuc05goster);
      	setSonuc06(sonuc06goster);
      	setSonuc07(sonuc07goster);
      	setSonuc08(sonuc08goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc05goster = null;
      	const sonuc06goster = null;
      	const sonuc07goster = null;
      	const sonuc08goster = null;
      	setSonuc05(sonuc05goster);
      	setSonuc06(sonuc06goster);
      	setSonuc07(sonuc07goster);
      	setSonuc08(sonuc08goster);
      }
    };
  
    soruBir();

    
// soru 01
// 
// Soru 02
    const soruIki = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-02/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru02 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc011goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_11.jpg"/></div>;
      	setSonuc011(sonuc011goster);        
        //console.log(sonuc01goster);
      } else {
      	const sonuc011goster = null;
      	setSonuc011(sonuc011goster);
      }
    };
  
    soruIki();

    
// soru 02
// 
// 

// Soru 03
    const soruUc = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-03/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru03 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc16goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_16.jpg"/></div>;
        const sonuc17goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_17.jpg"/></div>;
      	setSonuc16(sonuc16goster);
      	setSonuc17(sonuc17goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc16goster = null;
      	const sonuc17goster = null;
      	setSonuc16(sonuc16goster);
      	setSonuc17(sonuc17goster);
      }
    };
  
    soruUc();

    
// soru 03
// 


// 
// Soru 04
    const soruDort = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-04/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru04 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc19goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_19.jpg"/></div>;
        const sonuc20goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_20.jpg"/></div>;
        const sonuc21goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_21.jpg"/></div>;
      	setSonuc19(sonuc19goster);
      	setSonuc20(sonuc20goster);
      	setSonuc21(sonuc21goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc19goster = null;
      	const sonuc20goster = null;
      	const sonuc21goster = null;
      	setSonuc19(sonuc19goster);
      	setSonuc20(sonuc20goster);
      	setSonuc21(sonuc21goster);
      }
    };
  
    soruDort();

    
// soru 04
// 

// 
// Soru 05
    const soruBes = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-05/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru05 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc23goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_23.jpg"/></div>;
        const sonuc24goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_24.jpg"/></div>;
        const sonuc25goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_25.jpg"/></div>;
      	setSonuc23(sonuc23goster);
      	setSonuc24(sonuc24goster);
      	setSonuc25(sonuc25goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc23goster = null;
      	const sonuc24goster = null;
      	const sonuc25goster = null;
      	setSonuc23(sonuc23goster);
      	setSonuc24(sonuc24goster);
      	setSonuc25(sonuc25goster);
      }
    };
  
    soruBes();

    
// soru 05
// 

// 
// Soru 07
    const soruYedi = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-07/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru07 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc23goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_27.jpg"/></div>;
      	setSonuc27(sonuc27goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc27goster = null;
      	setSonuc27(sonuc27goster);
      }
    };
  
    soruYedi();

    
// soru 07
// 

// Soru 08
    const soruSekiz = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/colchicum-sonuc-08/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru08 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc30goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_30.jpg"/></div>;
        const sonuc31goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_31.jpg"/></div>;
        const sonuc32goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_32.jpg"/></div>;
        const sonuc33goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_33.jpg"/></div>;
      	setSonuc30(sonuc30goster);
      	setSonuc31(sonuc31goster);
      	setSonuc32(sonuc32goster);
      	setSonuc33(sonuc33goster);
        
        //console.log(sonuc01goster);
      } else {
      	const sonuc30goster = null;
      	const sonuc31goster = null;
      	const sonuc32goster = null;
      	const sonuc33goster = null;
      	setSonuc30(sonuc30goster);
      	setSonuc31(sonuc31goster);
      	setSonuc32(sonuc32goster);
      	setSonuc33(sonuc33goster);
      }
    };
  
    soruSekiz();

    
// soru 08
// 


// 
// Soru 02
// soru 12
// 
// 
// 

  
  
  }, [postId]);


const slide01goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_01.jpg"/></div>;
const slide02goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_02.jpg"/></div>;
const slide03goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_03.jpg"/></div>;
const slide04goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_04.jpg"/></div>;

const slide05goster = sonuc05goster;
const slide06goster = sonuc06goster;
const slide07goster = sonuc07goster;
const slide08goster = sonuc08goster;

const slide09goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_09.jpg"/></div>;
// const slide10goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_10.jpg"/></div>;

const slide11goster = sonuc011goster;



const slide12goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_12.jpg"/></div>;
const slide13goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_13.jpg"/></div>;
const slide14goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_14.jpg"/></div>;

// const slide15goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_15.jpg"/></div>;

const slide16goster = sonuc16goster;
const slide17goster = sonuc17goster;

// const slide18goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_18.jpg"/></div>;


const slide19goster = sonuc19goster;
const slide20goster = sonuc20goster;
const slide21goster = sonuc21goster;

// const slide22goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_22.jpg"/></div>;

const slide23goster = sonuc23goster;
const slide24goster = sonuc24goster;
const slide25goster = sonuc25goster;

// const slide26goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_26.jpg"/></div>;

const slide27goster = sonuc27goster;



const slide28goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_28.jpg"/></div>;
//const slide29goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_29.jpg"/></div>;

const slide30goster = sonuc30goster;
const slide31goster = sonuc31goster;
const slide32goster = sonuc32goster;
const slide33goster = sonuc33goster;



const slide34goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_34.jpg"/></div>;
const slide35goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_35.jpg"/></div>;
const slide36goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_36.jpg"/></div>;
const slide37goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_37.jpg"/></div>;
const slide38goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_38.jpg"/></div>;
const slide39goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_39.jpg"/></div>;
const slide40goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_40.jpg"/></div>;
const slide41goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_41.jpg"/></div>;
const slide42goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_42.jpg"/></div>;
const slide43goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/colchicum/Colchicum_Dispert_Medikal_Sunum_AGUSTOS24-1_Page_43.jpg"/></div>;

const slides = [slide01goster, slide02goster, slide03goster, slide05goster, slide06goster, slide07goster, slide08goster, slide09goster, slide11goster, slide12goster, slide13goster, slide14goster, slide16goster, slide17goster, slide19goster, slide20goster, slide21goster, slide23goster, slide24goster, slide25goster, slide27goster, slide28goster, slide30goster, slide31goster, slide32goster, slide33goster, slide34goster, slide35goster, slide36goster, slide37goster, slide38goster, slide39goster, slide40goster, slide41goster, slide42goster, slide43goster];
var filtered = slides.filter(Boolean);
const listItems = filtered.map((number) =>  <SwiperSlide>{number}</SwiperSlide>);

  return (
    <>
      <Swiper
        effect={"fade"}
        pagination={{
          type: "fraction",
        }}
        keyboard={{
          enabled: true,
        }}
        navigation={true}
        modules={[EffectFade, Navigation, Pagination, Keyboard]}
        className="mySwiper"
      >
        {listItems}
      </Swiper>
  
      {records.map((post) => (
        <div className="container d-none my-4" key={post.id}>
          <div className="sunum-adi">Sunum Adi: {post.title}</div>
  		  <div className="sunum-adi">Sunum Tarihi: {post.tarih}</div>
  		  <div className="sunum-adi">Sunan Kisi: {post.uid}</div>
        </div>
      ))}

<div className={`col d-flex flex-column align-items-center justify-content-center pb-5 footer-${location.pathname.split("/")[2]}`}>

<div className="card mx-2" style={{width: "6.75rem"}}>
  <a href="https://qr.recordatifocus.com/hippurinkub" className="text-decoration-none text-black" target="_blank"><img alt="" src="https://sorular.recordatifocus.com/sites/api.recordatifocus.com/themes/bootstrap_dofo/images/KUB-COLCHICUM.png" className="img-fluid" /></a>
  <div className="py-1 " style={{backgroundColor: "#655DC6"}}>
    <a href="https://qr.recordatifocus.com/hippurinkub" className="text-decoration-none text-white" target="_blank"><div className="text-center fw-bold">COLCHICUM</div></a>
  </div>
</div>
<p class="pb-5 mx-3 fw-bold footer-link-undefined">www.recordati.com.tr</p>
</div>

  
    </>
  );
};

export default Colchicum;
